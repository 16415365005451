// METAR wind uses true heading
// ATIS/AWOS wind uses magnetic heading
// runway numbers are based on magnetic heading

import { getMETAR } from './metar'
import { Metar } from '../types'

// weather can be received not only from METAR, but for now we only have that
export const getWeatherData = async (weatherStation: string): Promise<Metar> => {
  const data = await getMETAR(weatherStation.toUpperCase())

  // use lowercase for units
  if (data.wind?.unit) {
    data.wind.unit = data.wind.unit.toLowerCase()
  }

  return data
}
