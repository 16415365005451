import performanceDataLYUAP from 'assets/aircraft/LYUAP/performance.json'
import preChecklistDataLYUAP from 'assets/aircraft/LYUAP/pre-checklist.json'
import performanceDataLYMEU from 'assets/aircraft/LYMEU/performance.json'
import preChecklistDataLYMEU from 'assets/aircraft/LYMEU/pre-checklist.json'

import { Aircraft, AircraftPerformance, Checklist, /* AircraftPerformance, Airfield, Metar */ } from '../types'

export const getMyAircraft = (): Aircraft => {
  const activeAircraft = localStorage.getItem('activeAircraft') ?? 'LY-MEU'

  if (activeAircraft === 'LY-MEU') {
    return {
      performance: performanceDataLYMEU as AircraftPerformance,
      checklists: {
        pre: preChecklistDataLYMEU as Checklist,
      },
    }
  } else {
    return {
      performance: performanceDataLYUAP as AircraftPerformance,
      checklists: {
        pre: preChecklistDataLYUAP as Checklist,
      },
    }
  }
}

/* export function computeTakeOff (aircraftPerformance: AircraftPerformance, weatherData: Metar, airportData: Airfield) {

} */
