import React, { useCallback } from 'react'
import { Icon } from '../SvgIcon/paths'
import { SvgIcon } from '../SvgIcon'
import PropTypes from 'prop-types'
import './index.css'

export type InputProps = {
  label?: string
  helperText?: string
  leftIcon?: Icon
  rightIcon?: Icon
  behaviour?: string
  disabled?: boolean
  onChange?: (v: string) => void
  onLeftIconClick?: () => void
  onRightIconClick?: () => void
  inputClassName?: string
  min?: number
  type?: string
  defaultValue?: string
}

export const Input = ({ label, helperText, leftIcon, rightIcon, behaviour, disabled, onChange, onLeftIconClick, onRightIconClick, inputClassName, ...inputProps }: InputProps) => {
  const computedBehaviour = disabled ? 'disabled' : behaviour
  // @ts-ignore
  const onValueChange = useCallback((ev) => onChange && onChange(ev.target.value), [onChange])

  return (
    <div className={`input input${computedBehaviour ? `--${computedBehaviour}` : ''}`}>
      {
        label !== undefined ?
          <div className="input__label">
            { label }
          </div>
          : null
      }

      <div className="input__wrapper">
        {
          leftIcon ?
            <div className={`input__icon-box ${onRightIconClick ? 'input__icon-cursor' : ''}`} onClick={onLeftIconClick}>
              <SvgIcon code={leftIcon} size={1} />
            </div>
            : null
        }
        <input
          type="text"
          onChange={onChange ? onValueChange : undefined}
          {...inputProps}
          disabled={disabled}
          className={`${inputClassName} input__itself ${leftIcon ? 'input__itself--no-left' : ''} ${rightIcon ? 'input__itself--no-right' : ''}`}
        />
        {
          rightIcon ?
            <div className={`input__icon-box ${onRightIconClick ? 'input__icon-cursor' : ''}`} onClick={onRightIconClick}>
              <SvgIcon code={rightIcon} size={1} />
            </div>
            : null
        }
      </div>

      {
        helperText !== undefined ?
          <div className="input__helper">
            { helperText }
          </div>
          : null
      }
    </div>
  )
}

Input.propTypes = {
  /**
   * Special behaviour
   */
  behaviour: PropTypes.oneOf(['default', 'error', 'success']),

  /**
   * Whether is disabled or not
   */
  disabled: PropTypes.bool,

  /**
   * Label, can be any node
   */
  label: PropTypes.node,

  /**
   * Label text, shown below, may be colored
   */
  helperText: PropTypes.string,

  /**
   * Left icon code, icon follows the color effect
   */
  leftIcon: PropTypes.string,

  /**
   * Right icon code, icon follows the color effect
   */
  rightIcon: PropTypes.string,

  /**
   * Possible input classname
   */
  inputClassName: PropTypes.string,

  /**
   * An onChange helper to write less code, you don't need to use it
   */
  onChange: PropTypes.func,

  /**
   * Extra input props
   */
  inputProps: PropTypes.object,

  /**
   * Left icon click callback
   */
  onLeftIconClick: PropTypes.func,

  /**
   * Right icon click callback
   */
  onRightIconClick: PropTypes.func,
}

Input.defaultProps = {
  behaviour: 'default',
  inputClassName: '',
  disabled: false,
}
