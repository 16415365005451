import React, { Fragment, useCallback, forwardRef, useState, useEffect, PropsWithoutRef, ForwardedRef } from 'react'
import { Checklist as ChecklistType, ChecklistItem as ChecklistItemType } from 'types'
import { ChecklistItem } from 'components/ChecklistItem'
import { slugify } from '../../utils/text'
import './index.css'

export type ChecklistProps = {
  data: ChecklistType
}

// TODO: move localStorage stuff to the checklist screen!

export const Checklist = forwardRef<HTMLDivElement, ChecklistProps>(({data}: PropsWithoutRef<ChecklistProps>, reference: ForwardedRef<HTMLDivElement>) => {
  let missingChecksCount = 0
  const [checkState, setCheckState] = useState(JSON.parse(localStorage.getItem('checks') || '{}'))

  const updateCheck = useCallback((k: string) => (v: boolean) => {
    checkState[k] = v
    setCheckState({...checkState})
    localStorage.setItem('checks', JSON.stringify(checkState))
  }, [checkState])

  const renderItems = useCallback((items: ChecklistItemType[], sId: string) => {
    return items.map((row, i) => {
      const ck = `${sId}-i${i}-${slugify(row.check)}`

      if (row.expectState) {
        // UGLYHACK-3000, 'missingChecksCount' can only be used INSIDE render() AFTER the checklist
        // eslint-disable-next-line react-hooks/exhaustive-deps
        missingChecksCount += checkState[ck] ? 0 : 1

        return (
          <ChecklistItem
            key={i}
            label={row.check}
            expect={row.expectState}
            onChange={updateCheck(ck)}
            defaultChecked={checkState[ck] ?? false}
          />
        )
      } else if (row.options) {
        // uglyhack, see above
        missingChecksCount += checkState[ck] ? 0 : 1

        return (
          <Fragment key={i}>
            <div className="checklist__select-row">
              <span>{row.check}</span>
              <select
                value={checkState[ck] ?? 0}
                className={checkState[ck] ? '' : 'attention'}
                // @ts-ignore
                onChange={ev => updateCheck(ck)(ev.target.value)}
              >
                {
                  row.options.map((o, j) => <option key={j} value={j}>{ o.expectState }</option>)
                }
              </select>
            </div>
            {
              checkState[ck] ?
                renderItems(row.options[Number(checkState[ck] ?? 0)].items, slugify(row.check))
                : null
            }
          </Fragment>
        )
      } else {
        return <span>config error</span>
      }
    })
  }, [checkState, updateCheck])

  useEffect(() => {
    // @ts-ignore
    if (!reference.current) {
      return
    }

    // @ts-ignore
    reference.current.reset = () => {
      localStorage.removeItem('checks')
      setCheckState({})
    }
  }, [reference])

  return (
    <div
      ref={reference}
      className="checklist"
    >
      {
        data.map((section, i) => {
          return (
            <section key={i}>
              <div className="section-name">{ section.sectionName }</div>
              <div>
                { renderItems(section.items, `s${i}-${slugify(section.sectionName)}`) }
              </div>
            </section>
          )
        })
      }
      <div className="checklist__stats">
        {
          missingChecksCount > 0 ?
            <span className="checklist__stats-bad">{ missingChecksCount } check(s) missing</span>
            :
            <span className="checklist__stats-good">All checks clear 👌</span>
        }
      </div>

    </div>
  )
})
