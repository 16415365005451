const
  C_Y = 365 * 24 * 3600 * 1000,
  C_Mo = 30 * 24 * 3600 * 1000,
  C_D = 24 * 3600 * 1000,
  C_H = 3600 * 1000,
  C_M = 60 * 1000

/**
 * Produce a relative time string (e.g. '2d 4h ago')
 */
export function relativeTimeString (input: string | Date): string {
  const
    now = new Date(),
    ts = input instanceof Date ? input : new Date(input),
    diff = now.getTime() - ts.getTime()

  const fifo: Array<string> = []

  fifo.push = function () {
    if (this.length >= 2) {
      this.shift()
    }

    // @ts-ignore
    return Array.prototype.push.apply(this, arguments)
  }

  const
    dy = Math.floor(diff / C_Y),
    dmo = Math.floor((diff - dy * C_Y) / C_Mo),
    dd = Math.floor((diff - dy * C_Y - dmo * C_Mo) / C_D),
    dh = Math.floor((diff - dy * C_Y - dmo * C_Mo - dd * C_D) / C_H),
    dm = Math.floor((diff - dy * C_Y - dmo * C_Mo - dd * C_D - dh * C_H) / C_M),
    ds = Math.floor((diff - dy * C_Y - dmo * C_Mo - dd * C_D - dh * C_H - dm * C_M) / 1000)

  ds && fifo.push(`${ds}s`)
  dm && fifo.push(`${dm}m`)
  dh && fifo.push(`${dh}h`)
  dd && fifo.push(`${dd}d`)
  dmo && fifo.push(`${dmo}m`)
  dy && fifo.push(`${dy}y`)

  if (!fifo.length) {
    return 'right now'
  }

  const offsetString = fifo.reverse().join(' ')

  return diff >= 0 ? `${offsetString} ago` : `in ${offsetString}`
}
