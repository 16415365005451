import React, { useCallback, useEffect, useState } from 'react'
import './index.css'

export type ChecklistItemProps = {
  label: string
  expect: string
  defaultChecked: boolean
  onChange: (v: boolean) => void
}

export const ChecklistItem = ({label, expect, defaultChecked, onChange}: ChecklistItemProps) => {
  const [checked, setChecked] = useState(defaultChecked ?? false)

  const onCheck = useCallback(() => {
    const newChecked = !checked

    onChange && onChange(newChecked)
    setChecked(newChecked)
  }, [checked, onChange])

  useEffect(() => {
    setChecked(defaultChecked ?? false)
  }, [defaultChecked])

  return (
    <label className="checklist-item">
      <span className="checklist-item__input">
        <input
          type="checkbox"
          checked={checked}
          onChange={onCheck}
        />
        <span className="checklist-item__control">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            focusable="false"
          >
            <path
              fill="none"
              strokeWidth="3"
              d="M1.73 12.91l6.37 6.37L22.79 4.59"
            />
          </svg>
        </span>
      </span>
      <div className="checklist-item__texts">
        <span>{ label }</span>
        <span>{ expect }</span>
      </div>
    </label>
  )
}
