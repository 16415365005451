import { computeActiveRunway, getMyAirfield } from '../../services/airfield'
import React, { useEffect, useState } from 'react'
import { Runway, WindInfo } from '../../types'
import './index.css'

export function TakeoffScreen () {
  // ARD -- Active Runway Data
  const [ard, setARD] = useState<{runway: Runway, wind: WindInfo} | undefined>()

  useEffect(() => {
    computeActiveRunway(getMyAirfield().code).then(setARD)
  }, [setARD])

  if (ard === undefined) {
    return null
  }

  // compute head and crosswind
  // R - pos, L - neg
  const
    trigArg = Math.PI * (Number(ard.wind.direction) - ard.runway.trueDir) / 180,
    headWind = Math.round(10 * Number(ard.wind.speed) * Math.cos(trigArg)) / 10,
    crossWind = Math.round(10 * Number(ard.wind.speed) * Math.sin(trigArg)) / 10

  return (
    <div className="page page-takeoff">
      <table className="page-takeoff__params-table">
        <tbody>
          <tr><td>Active runway</td><td>{ard.runway.id} {ard.runway.pattern === 'L' ? 'left' : 'right'}</td></tr>
          <tr><td>Headwind</td><td>{headWind} {ard.wind.unit}</td></tr>
          <tr><td>Crosswind</td><td>{Math.abs(crossWind)} {ard.wind.unit} {crossWind > 0 ? 'right️' : 'left️'}</td></tr>
        </tbody>
      </table>
    </div>
  )
}
