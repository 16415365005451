import React, { useCallback, useEffect, useState } from 'react'
import { getMyAircraft } from 'services/aircraft'
import { Input } from 'components/Input'
import { LoadArm } from '../../types'
import './index.css'

// TODO: add reset button

const cachedValue = JSON.parse(localStorage.getItem('wnb') ?? 'null') as LoadArm[] | null

export function WeightScreen () {
  const pd = getMyAircraft().performance
  const [arms, setArms] = useState<Array<LoadArm>>(cachedValue ?? pd.wnb.arms)
  const [cgPos, setCGPos] = useState(0)
  const [totalWeight, setTotalWeight] = useState(0)

  const recompute = useCallback(() => {
    const totalMoment = arms.reduce((prev, curr) => prev + curr[1] * curr[2] * (curr[3] ?? 1), 0)
    const totalWeight = arms.reduce((prev, curr) => prev + curr[1] * (curr[3] ?? 1), 0)

    setTotalWeight(totalWeight)
    setCGPos(totalMoment / totalWeight)
  }, [arms])

  const onChange = useCallback((arm: number) => (weight: string) => {
    const newArms = [...arms]

    newArms[arm][1] = parseInt(weight, 10)
    recompute()

    setArms(newArms)
    localStorage.setItem('wnb', JSON.stringify(newArms))
  }, [arms, recompute])

  useEffect(recompute, [recompute])

  const isWeightOk = totalWeight <= pd.wnb.mtow
  const isBalanceOk = cgPos >= pd.wnb.forwardLimit && cgPos <= pd.wnb.rearwardLimit

  return (
    <div className="page page-weight">
      {
        arms.map((arm, i) =>
          <div key={i}>
            <Input
              min={0}
              type="number"
              label={arm[0]}
              onChange={onChange(i)}
              defaultValue={String(arm[1])}
            />
            <br/>
          </div>
        )
      }
      <div style={{color: `var(--color-${isWeightOk ? 'success' : 'error'})`}}>Weight: {Math.round(totalWeight)} kg</div>
      <div style={{color: `var(--color-${isBalanceOk ? 'success' : 'error'})`}}>CG pos: {Math.round(cgPos)} mm</div>
    </div>
  )
}
