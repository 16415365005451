import React from 'react'
import './index.css'

const allAircraft = ['LY-MEU', 'LY-UAP']

export type TopBarProps = {
  onChange: (v: string) => void
}

export function TopBar ({onChange}: TopBarProps) {
  const activeAircraft = localStorage.getItem('activeAircraft') ?? 'LY-MEU'

  return (
    <div className="topbar">
      <span>Active aircraft:</span>
      <select
        name="airplane"
        onChange={ev => onChange(ev.target.value)}
        defaultValue={activeAircraft}
      >
        { allAircraft.map((el, i) => <option key={i} value={el}>{el}</option>) }
      </select>
    </div>
  )
}
