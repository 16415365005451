export const computeHumidity = (t: number, td: number): number => {
  return 100 * (Math.exp((17.625 * td) / (243.04 + td)) / Math.exp((17.625 * t) / (243.04 + t)))
}

export const getAngularDiff = (alpha: number, beta: number): number => {
  return Math.abs((alpha - beta) % 360)
}

export const getFloatCoords = (input: string): number => {
  let sign = 1
  const segments: Array<number> = []

  input.toUpperCase().split(' ').forEach((seg, i) => {
    if (i === 2 && ['S', 'W'].includes(seg.slice(-1))) {
      sign = -1
    }
    segments.push(Number(seg.replace(/\D+/g, '')))
  })

  return sign * (segments[0] + segments[1] / 60 + segments[2] / 3600)
}
