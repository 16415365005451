// surface codes -- https://en.wikipedia.org/wiki/Runway#Surface_type_codes

// @ts-ignore
import airfieldEYKS from 'assets/airfields/EYKS.json'

import { getAngularDiff } from '../utils/physics'
import { getWeatherData } from './weather'
import { Airfield, Runway, WindInfo } from '../types'

const airfields: Record<string, Airfield> = {
// @ts-ignore
  EYKS: airfieldEYKS,
}

export function getMyAirfield () {
  return airfields.EYKS
}

export function getAirfieldByCode (airfieldCode: string): Airfield {
  const code = airfieldCode.toUpperCase()

  if (!airfields[code]) {
    throw new Error(`Data for airfield "${code}" not found`)
  }

  return airfields[code]
}

export const computeActiveRunway = async (airfieldCode: string): Promise<{runway: Runway, wind: WindInfo}> => {
  let bestRunway: Runway
  const airfield = getAirfieldByCode(airfieldCode)
  const weatherData = await getWeatherData(airfield.weatherStation)
  const windDir = weatherData.wind.direction ?? 0    // true heading

  airfield.runways.forEach(r => {
    if (!bestRunway || getAngularDiff(r.trueDir, windDir) < getAngularDiff(bestRunway.trueDir, windDir)) {
      bestRunway = {...r}
    }
  })

  // bestRunway always exists

  return {
    // @ts-ignore
    runway: bestRunway,
    wind: weatherData.wind,
  }
}
