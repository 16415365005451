import React, { useCallback, useState } from 'react'
import { ChecklistScreen } from 'screens/Checklist'
import { NotFoundScreen } from 'screens/NotFound'
import { WeatherScreen } from 'screens/Weather'
import { TakeoffScreen } from 'screens/Takeoff'
import { WeightScreen } from 'screens/Weight'
import { TopBar } from './components/TopBar'
import { NavBar } from 'components/NavBar'
import ReactDOM from 'react-dom'
import './common.css'
import './index.css'

const App = () => {
  const [page, setPage] = useState('weather')
  const [_, setRegNr] = useState(localStorage.getItem('activeAircraft') ?? 'LY-MEU')

  const onAircraftChange = useCallback((v: string) => {
    setRegNr(v)
    localStorage.setItem('activeAircraft', v)
  }, [])

  let content

  switch (page) {
    case 'checklist':
      content = <ChecklistScreen />
      break

    case 'weather':
      content = <WeatherScreen />
      break

    case 'weight':
      content = <WeightScreen />
      break

    case 'takeoff':
      content = <TakeoffScreen />
      break

    default:
      content = <NotFoundScreen />
  }

  return (
    <div className="app">
      <TopBar onChange={onAircraftChange} />
      { content }
      <NavBar selected={page} onSelect={setPage}/>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)
