import { SvgIcon } from 'components/SvgIcon'
import { Icon } from '../SvgIcon/paths'
import React from 'react'
import './index.css'

const levelData: Record<string, [Icon, string]> = {
  danger: ['alertCircle', 'var(--color-error)']
}

export type InfoListProps = {
  items: Array<{
    level: string
    label: string
  }>
}

export function InfoList ({items}: InfoListProps) {
  return (
    <div className="info-list">
      {
        items.map((el, i) =>
          <div key={i} className={`info-list__item info-list__item--${el.level}`}>
            <SvgIcon code={levelData[el.level][0]} size={1} />
            <span>{ el.label }</span>
          </div>
        )
      }
    </div>
  )
}
