import ReactDOMServer from 'react-dom/server'
import { ReactElement } from 'react'

/**
 * Turns a camelCased string into a dash-cased
 */
export function camelToDashCase (str: string): string {
  return str.replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)
}

/**
 * Tries to extract text from an unknown React node
 */
export function getElementText (element: ReactElement): string {
  // TODO: consider a faster replacement
  const renderResult = ReactDOMServer.renderToStaticMarkup(element)

  return renderResult.replace(/(<([^>]+)>)/gi, '')
}

/**
 * Turns ay text into a dash-lowercase string
 */
export function slugify (str: string | number): string {
  if (typeof str !== 'string') {
    throw Error('Can only slugify strings')
  }

  return str.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/gi, '')
}
