// https://www.atsb.gov.au/media/47763/carb_icing.pdf
// https://api.met.no/weatherapi/tafmetar/1.0/documentation
// TODO: try to fetch local data from http://aleksotas.eik.lt/meteo/ <=> http://aleksotas.eik.lt/meteo/index2.php?h=txt

import { computeHumidity, getFloatCoords } from 'utils/physics'
import { getAirfieldByCode } from '../../services/airfield'
import { getWeatherData } from '../../services/weather'
import React, { useEffect, useState } from 'react'
import { relativeTimeString } from 'utils/time'
import { InfoList } from 'components/InfoList'
import { Airfield, Metar } from '../../types'
import './index.css'

const SunCalc = require('suncalc')

const dangerCopies: Record<string, string> = {
  surfaceIce: 'Risk of surface icing',
  carbIce: 'Risk of carburetor icing',
  visibility: 'Non-VFR visibility',
  lowClouds: 'Low clouds',
  cumulonimbus: 'Cumulonimbus clouds',
  thunderstorm: 'Thunderstorm',
  hail: 'Hail',
  noVisData: 'No visibility data',
  heavyWeather: 'Heavy weather',
  nightSoon: 'Night is less than in an hour',
}

export function WeatherScreen () {
  const [wd, setWd] = useState<Metar | undefined>()
  const [ad, setAd] = useState<Airfield | undefined>()

  useEffect(() => {
    const airfield = getAirfieldByCode('EYKS')

    setAd(airfield)
    getWeatherData(airfield.weatherStation).then(setWd)
  }, [])

  if (!wd || !ad) {
    return null
  }

  const windUnit = wd.wind.unit.toLowerCase()
  const humidity = wd.temperature !== null && wd.dewpoint !== null ? Math.round(computeHumidity(wd.temperature, wd.dewpoint)) : '?'
  const dangers = new Set<string>()

  if (wd.temperature !== null && humidity >= 64 && wd.temperature <= 20 && wd.temperature >= -10) {
    dangers.add('carbIce')
  }

  // VFR, class G, below FL100
  // TODO: support other minimums
  if (wd.visibility !== null && wd.visibility < 5000) {
    dangers.add('visibility')
  }

  if (wd.visibility === null && !wd.cavok) {
    dangers.add('noVisData')
  }

  // compute day/night
  const now = new Date()
  const sunCalc = SunCalc.getTimes(now, getFloatCoords(ad.latitude), getFloatCoords(ad.longitude))

  // TODO: types for SunCalc.getTimes()
  // @ts-ignore
  if (now > sunCalc.sunset.getTime() - 60 * 60 * 1000 && now < sunCalc.sunset) {
    dangers.add('nightSoon')
  }

  (wd.weather || []).forEach(weather => {
    if (weather.abbreviation === 'TS') {
      dangers.add('thunderstorm')
    }
    if (weather.abbreviation === 'GR') {
      dangers.add('hail')
    }
    if (weather.abbreviation === '+') {
      dangers.add('heavyWeather')
    }
  });

  (wd.clouds || []).forEach(cloud => {
    // TODO: account for airfield and airspace
    if (cloud.altitude <= 1100 + 100 && cloud.abbreviation !== 'NSC') {
      dangers.add('lowClouds')
    }
    if (cloud.cumulonimbus) {
      dangers.add('cumulonimbus')
    }
  });

  return (
    <div className="page page-weather">
      <div className="header">{ relativeTimeString(wd.time) }</div>
      <table className="page-weather__params-table">
        <tbody>
          <tr>
            <td>Wind</td>
            <td>
              { wd.wind.speed } {windUnit}
              { wd.wind.gust && <span>, gusts {wd.wind.gust} {windUnit}</span> }
            </td>
          </tr>
          <tr>
            <td>QNH</td>
            <td>{ wd.altimeterInHpa }</td>
          </tr>
          <tr>
            <td>Temp</td>
            <td>{ wd.temperature }</td>
          </tr>
          <tr>
            <td>Humidity</td>
            <td>{ humidity }</td>
          </tr>
        </tbody>
      </table>

      <hr/>

      {
        dangers.size ?
          <InfoList items={Array.from(dangers).map(el => ({level: 'danger', label: dangerCopies[el]}))} />
          :
          <div>Weather is safe</div>
      }

      <hr/>

      <pre>
        { JSON.stringify(wd, null, 2) }
      </pre>
    </div>
  )
}
