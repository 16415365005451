// @ts-ignore
import parseMETAR from 'utils/metar'

import { Metar } from '../types'

export async function getMETAR (weatherStation: string): Promise<Metar> {
  // return parseMETAR('EYKA 191020Z 30008KT 240V010 9999 FEW017CB BKN068 03/M00 Q1001=')

  let metarObject: Metar
  let storage = JSON.parse(localStorage.getItem(`metar.${weatherStation}`) ?? 'null')

  if (!storage || storage.ts < (new Date()).getTime() - 35 * 60 * 1000) {
    const response = await fetch(`https://api.met.no/weatherapi/tafmetar/1.0/metar?icao=${weatherStation}`, {cache: 'no-cache'})
    const text = await response.text()
    const data = text.split('\n').filter(Boolean)
    const lastRow = data.slice(-1)[0]

    metarObject = parseMETAR(lastRow)

    storage = {
      ts: (new Date(metarObject.time)).getTime(),
      data,
    }

    localStorage.setItem(`metar.${weatherStation}`, JSON.stringify(storage))
  } else {
    metarObject = parseMETAR(storage.data.slice(-1)[0])
  }

  return metarObject
}
