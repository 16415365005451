import React, { useCallback, useRef, useState } from 'react'
import { Checklist as ChecklistType } from 'types'
import { getMyAircraft } from 'services/aircraft'
import { Checklist } from 'components/Checklist'
import NoSleep from 'nosleep.js'
import './index.css'

const noSleep = new NoSleep()

export function ChecklistScreen () {
  const ckListRef = useRef(null)
  const data = getMyAircraft().checklists.pre as ChecklistType
  const [sleepLocked, setSleepLocked] = useState(false)

  const toggleSleepLock = useCallback(() => {
    if (sleepLocked) {
      // off
      noSleep.disable()
    } else {
      noSleep.enable()
    }

    setSleepLocked(!sleepLocked)
  }, [sleepLocked])

  const clearChecks = useCallback(() => {
    if (ckListRef.current) {
      // @ts-ignore
      ckListRef.current.reset()
    }
  }, [ckListRef])

  return (
    <div className="page page-checklist">
      <div>
        <button onClick={toggleSleepLock}>
          { sleepLocked ? 'Reset sleep' : 'Disable sleep'}
        </button>
        &nbsp;
        <button onClick={clearChecks}>
          Clear checks
        </button>
      </div>
      <Checklist
        data={data}
        ref={ckListRef}
      />
    </div>
  )
}
