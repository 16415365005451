import { SvgIcon } from 'components/SvgIcon'
import { Icon } from '../SvgIcon/paths'
import React from 'react'
import './index.css'

const buttons: Array<[Icon, string]> = [
  ['weather', 'Weather'],
  ['weight', 'W&B'],
  ['checklist', 'Checklist'],
  ['takeoff', 'Takeoff'],
]

export type NavBarProps = {
  selected: string
  onSelect: (v: Icon) => void
}

export function NavBar ({selected, onSelect}: NavBarProps) {
  return (
    <div className="navbar">
      {
        buttons.map((el, i) =>
          <div
            key={i}
            onMouseDown={() => onSelect(el[0])}
            onTouchStart={() => onSelect(el[0])}
            className={`navbar-button ${selected === el[0] && 'navbar-button--selected'}`}
          >
            <SvgIcon code={el[0]} />
            <span>{ el[1] }</span>
          </div>
        )
      }
    </div>
  )
}
